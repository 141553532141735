import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";

const SiteLogoImg = styled.img`
  width: 173px;
  height: auto;
`;

const SiteLogo = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpLogo {
            edges {
              node {
                url {
                  source_url
                }
              }
            }
          }
        }
      `}
      render={(props) => (
        <div>
          <SiteLogoImg
            src={props.allWordpressWpLogo.edges[0].node.url.source_url}
            alt="Site Logo"
          />
        </div>
      )}
    />
  );
};

export default SiteLogo;
