import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import styled from "styled-components";
import Logo from "./Logo";

const SiteInfoWrapper = styled.div`
  flex-grow: 1;
`;

const SiteInfo = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressSiteMetadata {
          edges {
            node {
              name
              description
            }
          }
        }
      }
    `}
    render={(props) => (
      <SiteInfoWrapper>
        <Link to={`/home`}>
          <Logo />
        </Link>
      </SiteInfoWrapper>
    )}
  />
);

export default SiteInfo;
