import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import styled from "styled-components";
import SiteInfo from "../structure/SiteInfo";

const MainMenuWrapper = styled.div`
  display: flex;
  max-width: 960px;
  margin: 10px auto;
`;
const MenuItem = styled(Link)`
  margin-right: 20px;
`;

const MainMenu = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Top Menu" } }) {
          edges {
            node {
              items {
                title
                object_slug
                wordpress_id
              }
            }
          }
        }
      }
    `}
    render={(props) => (
      <MainMenuWrapper>
        <SiteInfo />
        {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
          (item) => (
            <MenuItem to={`/${item.object_slug}`} key={item.wordpress_id}>
              {item.title}
            </MenuItem>
          )
        )}
      </MainMenuWrapper>
    )}
  />
);

export default MainMenu;
